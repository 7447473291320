.section {
  background: #f0f2f5;
  padding: 1px 10px;
  margin-bottom: 10px;
  border-radius: 10px;
}

.subsection {
  background: #ebfaeb;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
}

.section-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f0f2f5;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
}

.section-content {
  flex-grow: 1;
  font-weight: bold;
}