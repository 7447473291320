.score-badge {
  position: relative; /* Required for absolute positioning of children */
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: transparent;
  border: 5px solid;
  color: black;
  font-size: 24px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.score-container {
  display: flex;
  justify-content: center;
}